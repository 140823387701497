/* eslint-disable no-underscore-dangle */
import React, { useMemo, useContext } from 'react'

import { Row, Col } from '@zendeskgarden/react-grid'

import { random, ensureArray } from '@agnostack/lib-core'

import {
  Heading,
  Section,
  AppListings,
  ListingFooter,
} from '../../atoms'
import { GlobalState, GLOBAL_PARAMS, LISTINGS, LISTING_NAMES } from '../../../util'
import PageFragment from '../PageFragment'
import { ListingDescription, StyledGrid } from './Apps.style'

const randomListing = LISTING_NAMES[random(LISTING_NAMES.length - 1, 0)]

const Apps = ({ listing, fragments, ...renderProps }) => {
  const { [GLOBAL_PARAMS.LISTING]: listingContext } = useContext(GlobalState)

  const { showApps, activeListing, listingFragment } = useMemo(() => {
    const _showApps = (LISTING_NAMES.length > 1)
    const _activeListing = listing || listingContext || randomListing
    const _listingFragment = ensureArray(fragments).find(({
      node: {
        frontmatter: {
          match,
        } = {},
      } = {},
    }) => (match === `listing-${_activeListing}`))

    return {
      showApps: _showApps,
      activeListing: _activeListing,
      listingFragment: _listingFragment,
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listing, listingContext])

  if (!showApps) {
    return null
  }

  return (
    <Section id="zendesk-apps" {...renderProps}>
      <StyledGrid>
        <Row>
          <Col align="center">
            <Heading tag="2">
              <span>Zendesk</span>
              <sup>Ⓡ</sup>
              <span> Apps</span>
            </Heading>
            <AppListings listing={activeListing} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ListingDescription>
              <Heading tag="3">{LISTINGS[activeListing]?.name}</Heading>
              <PageFragment data={listingFragment} fragments={fragments} />
              <ListingFooter listing={activeListing} />
            </ListingDescription>
          </Col>
        </Row>
      </StyledGrid>
    </Section>
  )
}

export default Apps
