import React from 'react'

import { Grid, Row, Col } from '@zendeskgarden/react-grid'

import { NoWrap } from '../../../styles/core'
import { Heading, LinkButton, Section } from '../../atoms'
import { OverlayCol, Wrapper, IntegrationsImage } from './Integrations.style'

const Integrations = (renderProps) => (
  <Section id="integrations" {...renderProps}>
    <Grid>
      <Row>
        <Col md={6} align="left">
          <Heading tag="3">Complex Integrations Made Simple</Heading>
          <Wrapper>
            <p>
              Bring together your best-in-class Service Platform with the
              world&apos;s leading Commerce Platforms, Payment Gateways and Shipping
              Solutions. We make the complex simple and allow you to focus on
              providing better customer experiences rather than wasting time and
              money integrating complex systems.
            </p>
            <br />
            <b>
              Platform agnostic to grow with you from
              {' '}
              <NoWrap>day 1 to day 100.</NoWrap>
            </b>
          </Wrapper>
        </Col>
        <OverlayCol md={6}>
          <IntegrationsImage />
        </OverlayCol>
      </Row>
      <Row>
        <Col textAlign="center">
          <LinkButton href="https://partners.agnostack.com">
            Search Partners
          </LinkButton>
          <LinkButton isPrimary href="/integrations/all">
            All Integrations
          </LinkButton>
        </Col>
      </Row>
      <Row>
        <Col textAlign="center">
          <br />
          <p>Don&apos;t see an existing Integration/Add-On that you&apos;d like to see us add?</p>
          <p>
            <span>Let us know at </span>
            <a href="mailto:info@agnostack.com">info@agnostack.com</a>
            <span> amd we will look to add it to our product roadmap!</span>
          </p>
        </Col>
      </Row>
    </Grid>
  </Section>
)

export default Integrations
