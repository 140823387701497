import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Col } from '@zendeskgarden/react-grid'

import { ImageWrapper } from '../../../styles/core'
import {
  StyledSection,
  StyledGrid,
  StyledRow,
  StyledFeature,
  StyledHeading,
  StyledText,
} from './Features.style'

const FeatureText = ({ title, text }) => (
  <StyledFeature>
    <StyledHeading>{title}</StyledHeading>
    <StyledText>{text}</StyledText>
  </StyledFeature>
)

const Features = (renderProps) => {
  const data = useStaticQuery(graphql`
    query featuresBenefitsQuery {
      allFeaturesJson {
        edges {
          node {
            title
            text
          }
        }
      }
      orderHistoryImage: file(relativePath: {eq: "order-history.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: NONE
          )
        }
      }
      orderDetailsImage: file(relativePath: {eq: "order-details.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: NONE
          )
        }
      }
      shoppingCartImage: file(relativePath: {eq: "shopping-cart.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: NONE
          )
        }
      }
      ordersWatchlistImage: file(relativePath: {eq: "orders-watchlist.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: NONE
          )
        }
      }
    }
  `)

  const {
    allFeaturesJson: {
      edges: features,
    },
    orderHistoryImage,
    orderDetailsImage,
    shoppingCartImage,
    ordersWatchlistImage,
  } = data

  const featureImages = [
    <ImageWrapper width="100%" key="orderHistoryImage">
      <GatsbyImage
        image={orderHistoryImage.childImageSharp.gatsbyImageData}
        alt="Order History"
      />
    </ImageWrapper>,
    <ImageWrapper width="100%" key="orderDetailsImage">
      <GatsbyImage
        image={orderDetailsImage.childImageSharp.gatsbyImageData}
        alt="Order Details"
      />
    </ImageWrapper>,
    <ImageWrapper width="100%" key="ordersWatchlistImage">
      <GatsbyImage
        image={ordersWatchlistImage.childImageSharp.gatsbyImageData}
        alt="Orders Watchlist"
      />
    </ImageWrapper>,
    <ImageWrapper width="100%" key="shoppingCartImage">
      <GatsbyImage
        image={shoppingCartImage.childImageSharp.gatsbyImageData}
        alt="Shipping Cart"
      />
    </ImageWrapper>
  ]

  return (
    <StyledSection {...renderProps}>
      <StyledGrid id="features">
        {features.map((feature, index) => {
          const {
            node: { title, text },
          } = feature

          return (
            <StyledRow key={`feature-${index}`}>
              <Col md={6}>
                <FeatureText title={title} text={text} />
              </Col>
              <Col md={6}>{featureImages[index]}</Col>
            </StyledRow>
          )
        })}
      </StyledGrid>
    </StyledSection>
  )
}

export default Features
