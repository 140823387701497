import React from 'react'
import { Grid, Row, Col } from '@zendeskgarden/react-grid'

import { Heading, Section, ScheduleButton, ContactForm } from '../../atoms'

const LearnMore = ({ format, ...renderProps }) => (
  <Section format={format} {...renderProps}>
    <Grid>
      <Row>
        <Col md={12} align="center">
          <Heading tag="4" size="LG">
            Learn more about agnoStack!
          </Heading>
          <ContactForm />
          <ScheduleButton />
        </Col>
      </Row>
    </Grid>
  </Section>
)

export default LearnMore
