import styled from 'styled-components'

import { Grid } from '@zendeskgarden/react-grid'

// eslint-disable-next-line import/prefer-default-export
export const ListingDescription = styled.div`
  margin: 2em 0 4em;

  .listing-body {
    min-height: 20vh;
  }
`

export const StyledGrid = styled(Grid)`
  ${({ theme }) => `
    padding-left: 0px;
    padding-right: 0px;

    @media (min-width: ${theme.breakpoints.sm}) {
      padding-left: unset;
      padding-right: unset;
    }
  `}
`
