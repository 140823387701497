import React, { useState, useContext, useEffect, useMemo } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { Grid } from '@zendeskgarden/react-grid'

import { useInterval } from '@agnostack/lib-utils-react'
import { ensureArray, ensureObject, stringNotEmpty } from '@agnostack/lib-core'

import {
  getAnnouncementData,
  formatAnnouncement,
  GlobalState,
  GlobalDispatch,
  GLOBAL_ACTIONS,
  GLOBAL_MODES,
} from '../../../util'
import {
  Announcement,
  ColorSlider,
  ScheduleButton,
} from '../../atoms'
import {
  ImageWrapper,
  Tagline,
} from '../../../styles/core'
import {
  HeroSection,
  HeroRow,
  HeroTextCol,
  HeroImageCol,
  HeroHeading,
  HeroMainTitle,
  HeroContent,
  HeroBody,
  HeroCTA,
  StyledTypewriter,
} from './Hero.style'

const HeroSlot = ({
  format,
  heading,
  title,
  text,
  hideCTA,
  children,
  subtitles = [],
  announcement: _announcement,
  data: {
    allMdx: {
      edges = [],
    } = {},
  } = {},
}) => {
  const { display } = useContext(GlobalState)
  const announcement = _announcement || formatAnnouncement(
    getAnnouncementData(edges, display)
  )
  const {
    date: announcementDate,
    title: announcementTitle,
    link: announcementLink,
    summary: AnnouncementSummary,
  } = ensureObject(announcement)

  return (
    <>
      {heading && <HeroHeading>{heading}</HeroHeading>}
      {title && <HeroMainTitle>{title}</HeroMainTitle>}
      {subtitles.length > 0 && (
        <HeroMainTitle>
          <Tagline format={format}>
            <StyledTypewriter strings={subtitles} />
          </Tagline>
        </HeroMainTitle>
      )}
      <HeroContent>
        {text && <HeroBody>{text}</HeroBody>}
        {!hideCTA && (
          <HeroCTA>
            <ScheduleButton format="dark">Learn More</ScheduleButton>
          </HeroCTA>
        )}
      </HeroContent>
      {announcement && (
        <Announcement
          title={announcementTitle}
          format={format}
          hasDate={stringNotEmpty(announcementDate)}
          onClick={() => announcementLink && navigate(announcementLink)}
        >
          {AnnouncementSummary ? (
            <AnnouncementSummary />
          ) : announcementTitle}
        </Announcement>
      )}
      {children}
    </>
  )
}

const HeroSlider = ({
  heros,
  children,
  duration: durationProp = 9000,
  slide = true,
  ...renderProps
}) => {
  const [activePanel, setActivePanel] = useState(0)
  const dispatch = useContext(GlobalDispatch)
  const {
    node: {
      heading,
      title,
      subtitles,
      text,
      format,
      hideCTA, // NOTE: can add hideCTA in heros.json to any hero
      durations: subtitleDurations,
    },
  } = ensureObject(heros[activePanel])
  const duration = !subtitleDurations
    ? durationProp
    : subtitleDurations * 5 * (ensureArray(subtitles).length + 2) // Each title gets one count (plus beginning plus end), each count gets 5x duration

  useEffect(() => {
    if (activePanel) {
      dispatch({
        type: GLOBAL_ACTIONS.SET,
        payload: { activeFormat: heros[activePanel].node.format },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePanel])

  useInterval(() => {
    if (slide) {
      setActivePanel(activePanel === heros.length - 1 ? 0 : activePanel + 1)
    }
  }, duration)

  return (
    <>
      <ColorSlider activeFormat={format} />
      <HeroRow>
        <HeroTextCol>
          <HeroSlot
            key={`hero-${activePanel}`}
            {...renderProps}
            format={format}
            heading={heading}
            title={title}
            subtitles={subtitles}
            hideCTA={hideCTA}
            text={text}
          >
            {children}
          </HeroSlot>
        </HeroTextCol>
        <HeroImageCol>
          <ImageWrapper width="100%" height="auto">
            <img src="/images/illustration.svg" alt="agnoStack" />
          </ImageWrapper>
        </HeroImageCol>
      </HeroRow>
    </>
  )
}

const Hero = ({
  format,
  children,
  slide: slideProp,
  ...renderProps
}) => {
  const { display, slidable } = useContext(GlobalState)
  const data = useStaticQuery(graphql`
    query herosQuery {
      allHerosJson {
        edges {
          node {
            heading
            title
            subtitles
            text
            format
            durations
          }
        }
      }
    }
  `)

  const { allHerosJson: { edges: heros } } = data
  const slide = useMemo(() => (
    slidable && (slideProp || (display !== GLOBAL_MODES.DEBUG))
  ), [slidable, slideProp, display])

  return (
    <HeroSection>
      <Grid>
        <HeroSlider
          format={format}
          heros={heros}
          slide={slide}
          {...renderProps}
        >
          {children}
        </HeroSlider>
      </Grid>
    </HeroSection>
  )
}

export default Hero
