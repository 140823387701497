import styled from 'styled-components'

import { Heading, Section, Typewriter } from '../../atoms'

export const HeroSection = styled(Section)`
  @media (max-width: ${({ theme }) => theme.breakpoints.calc(theme.breakpoints.md, 'max')}) {
    height: auto;
    min-height: 100vh;
    align-items: flex-start;
    position: relative;
    overflow-x: hidden;
  }
`

export const HeroRow = styled.div`
  display: flex;

  @media (max-width: ${({ theme }) => theme.breakpoints.calc(theme.breakpoints.lg, 'max')}) {
    flex-direction column;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.calc(theme.breakpoints.md, 'max')}) {
    flex-direction column-reverse;
  }
`

export const HeroTextCol = styled.div`
  ${({ theme }) => `
    z-index: +1;
    width: 100%;

    @media (min-width: ${theme.breakpoints.lg}) {
      width: 55%;
    }
  `}
`

export const HeroImageCol = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    z-index: +1;
    width: 100%;

    @media (min-width: ${theme.breakpoints.lg}) {
      width: 45%;
    }
  `}
`

export const HeroHeading = styled(Heading).attrs(() => ({
  tag: '2',
}))`
  font-size: 1.1em;
  font-weight: 500;
  opacity: .8;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  min-height: 18vh;
`

export const HeroMainTitle = styled(Heading).attrs(() => ({
  tag: '1',
}))`
  ${({ theme }) => `
    font-size: 2.5em;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;

    @media (min-width: ${theme.breakpoints.xs}) {
      font-size: 2.7em;
    }
  `}
`

export const HeroContent = styled.div`
  margin: 1em 0 2em 0;
  min-height: 180px;
`

export const HeroBody = styled(Heading).attrs(() => ({
  tag: '5',
}))`
  font-size: 1.2em;
  line-height: 1.3;
  margin: 0;
  padding: 0;
`

export const HeroCTA = styled.div`
  margin: 2em 0;
`

export const StyledTypewriter = styled(Typewriter).attrs(() => ({
  options: {
    delay: 50,
    deleteSpeed: 20,
    cursor: '_',
  },
}))`
  font-size: 3.2em;
`
