import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { Grid, Row, Col } from '@zendeskgarden/react-grid'

import { ImageWrapper } from '../../../styles/core'
import { Section } from '../../atoms'

const CustomerImage = ({ data, asset, name }) => (
  <ImageWrapper width="100%">
    <GatsbyImage image={data[asset].childImageSharp.gatsbyImageData} alt={name} />
  </ImageWrapper>
)

const CustomersRow = styled(Row)`
  padding-bottom: 10px;
`

const Customers = (renderProps) => {
  const data = useStaticQuery(graphql`
    query customerLogosQuery {
      customerLogo1: file(relativePath: {eq: "customer-logo-pier1.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: NONE
          )
        }
      }
      customerLogo2: file(relativePath: {eq: "customer-logo-garrett-popcorn.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: NONE
          )
        }
      }
      customerLogo3: file(relativePath: {eq: "customer-logo-radio-shack.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: NONE
          )
        }
      }
      customerLogo4: file(relativePath: {eq: "customer-logo-decathlon.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: NONE
          )
        }
      }
      customerLogo5: file(relativePath: {eq: "customer-logo-hamilton-beach.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: NONE
          )
        }
      }
      customerLogo6: file(relativePath: {eq: "customer-logo-nordic-ware.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: NONE
          )
        }
      }
      customerLogo7: file(relativePath: {eq: "customer-logo-baby-bjorn.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: NONE
          )
        }
      }
    }
  `)

  return (
    <Section id="agnostack-customers" {...renderProps}>
      <Grid>
        <CustomersRow>
          <Col size={6} md={3} center>
            <CustomerImage
              data={data}
              asset="customerLogo1"
              name="Pier1"
            />
          </Col>
          <Col size={6} md={3} center>
            <CustomerImage
              data={data}
              asset="customerLogo2"
              name="Garrett Popcorn"
            />
          </Col>
          <Col size={6} md={3} center>
            <CustomerImage
              data={data}
              asset="customerLogo3"
              name="Le Creuset"
            />
          </Col>
          <Col size={6} md={3} center>
            <CustomerImage
              data={data}
              asset="customerLogo4"
              name="Decathlon"
            />
          </Col>
        </CustomersRow>
        <CustomersRow justifyContent="center">
          <Col size={6} md={3} center>
            <CustomerImage
              data={data}
              asset="customerLogo5"
              name="Hamilton Beach"
            />
          </Col>
          <Col size={6} md={3} center>
            <CustomerImage
              data={data}
              asset="customerLogo6"
              name="NordicWare"
            />
          </Col>
          <Col size={6} md={3} center>
            <CustomerImage
              data={data}
              asset="customerLogo7"
              name="BabyBjorn"
            />
          </Col>
        </CustomersRow>
      </Grid>
    </Section>
  )
}

export default Customers
