import styled from 'styled-components'
import { Grid, Row } from '@zendeskgarden/react-grid'

import { Heading, Section } from '../../atoms'
import { colors } from '../../../theme/colors'

export const StyledSection = styled(Section)`
  background: ${colors.tertiary};
  background: linear-gradient(180deg, ${colors.tertiary} 0%, ${colors.light} 200px);
`

export const StyledGrid = styled(Grid)`
  div:nth-of-type(even) {
    flex-direction: row-reverse;
  }
`

export const StyledRow = styled(Row)`
  ${({ theme }) => `
    margin-top: 3em;

    @media (min-width: ${theme.breakpoints.lg}) {
      margin-top: -3em;
    }
  `}
`

export const StyledFeature = styled.div`
  ${({ theme }) => `
    @media (min-width: ${theme.breakpoints.sm}) {
      padding-right: 5em; 
      padding-left: 2em; 
    }
  `}
`

export const StyledHeading = styled(Heading).attrs(() => ({
  tag: '2',
}))`
  font-size: 2em;
`

export const StyledText = styled.div.attrs(({ children }) => ({
  dangerouslySetInnerHTML: {
    __html: children,
  },
  children: undefined,
}))``
